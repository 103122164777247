import React from 'react';
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring';
import InputLabel from '@mui/material/InputLabel';
import axios from '../../src/utils/axiosConfig';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'js-cookie';
import Grid from '@mui/material/Grid2';
import MenuTab from './MenuTab';
import MenuTabMobile from './MenuTabMobile'
import { getOrgId, setOrgId, setOrgData, getOrgData, addUserHome, setLoading, setUserHomeDetails, setGroupDetails, setTypeButton } from '../reducer/dashboardSlice';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding:'3px 5px 0px 5px',
  },
  grow: {
    flexGrow: 1,
  },title: {
    flexGrow: 1,  
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  title1: {
    position: 'relative',
    flexGrow: 1,  
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
   // backgroundColor: fade(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,  
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    title: {
      flexGrow: 1.5,  
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  }, 
  root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    }
  }));
  
  const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
    );
  });

  Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
  };

export default (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {typeButton,handleHome,handleIconCLick,menuTitle,selectedTab, setSelectedTab} = props;
  const [userOrgData, setUserOrgData] = React.useState('');
  const [org_name, setCurrentOrg] = React.useState('');
  const orgId = useSelector(getOrgId);
  const orgDetails = useSelector(getOrgData);
  let token = Cookies.get('token');
  const getOrg = (orgId)=>{
    let data = {
      newValue: typeButton,
      org : orgId
    }
    dispatch(addUserHome(data)).then((response)=>{
      dispatch(setLoading())
      dispatch(setUserHomeDetails(response.payload))
      dispatch(setTypeButton(response.meta.arg))
      dispatch(setGroupDetails(response.payload.groups))
      dispatch(setOrgId(response.payload.latestOrg))
      dispatch(setOrgData(response.payload.org))
      let typeData = response.payload;
      handleHome(typeData,typeButton)
    }).catch((e)=>{
        console.log(e)
    })
  }


  const handleChange =(event) => {
    let org_id = event.target.value;
    let data = {
      org : event.target.value 
    }
    dispatch(setOrgId(data.org));
    getOrg(data.org)
  };

  return (
    <div className={classes.grow}>
      <div className={classes.sectionDesktop}>
      <Grid container spacing={12} columns={16} > 
        <Grid size={8} > 
          {typeButton === 'Site Requirements' ? 
          <b><h3 style={{ color: "#7030a0", fontSize: "20px", marginBottom: "20px" }}>{typeButton}</h3></b>
          : <MenuTab selectedTab = {selectedTab} setSelectedTab = {setSelectedTab} handleHome={handleHome} handleIconCLick={handleIconCLick} menuTitle={menuTitle}/>
          }       
        </Grid>
           <Grid size={5}></Grid>
        <Grid size={3}  alignItems="right">          
          <FormControl style={{ width: "auto" }}>
            <InputLabel id="demo-simple-select-helper-label">Organization</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="organization"
              onChange={handleChange}
              name='org-select'
              value={orgId}
              options={orgDetails}
            >
              {orgDetails && orgDetails.map((item, index) => {
                return <MenuItem key={index} value={item.organization_id}>{item.org_name}</MenuItem>
              })}
            </Select>
          </FormControl>
           
        </Grid>
        </Grid>
      </div>

      <div className={classes.sectionMobile}>
        <Grid item xs={8}>
        {typeButton === 'Site Requirements' ? 
          <b><h3 style={{ color: "#7030a0", fontSize: "20px", marginBottom: "20px" }}>{typeButton}</h3></b>
          : <MenuTabMobile selectedTab = {selectedTab} setSelectedTab = {setSelectedTab} handleHome={handleHome} handleIconCLick={handleIconCLick} menuTitle={menuTitle}/>
        }
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "-webkit-fill-available"}}>
            <InputLabel id="demo-simple-select-helper-label">Organization</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="organization"
              onChange={handleChange}
              name='org-select'
              options={orgDetails}
              value={orgId}
            >
              {orgDetails && orgDetails.map((item, index) => {
                return <MenuItem key={index} value={item.organization_id}>{item.org_name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
      </div>
    </div>
  );
}